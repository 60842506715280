import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container,Navbar,Nav, Tabs, Tab } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import favImg03 from "../../../images/fav-img03.jpg"
import favImg04 from "../../../images/fav-img04.jpg"
import ReactMarkdown from "react-markdown"

const MgFavouritesList=(props)=>{
   
  const [key, setKey] = useState('restaurant-and-bars');
  const count = props.data.length
  var type = "odd"
  if(count % 2 == 0) {
      type = "even";
  }

  return (
    <section className="mg-favourites mg-fav-landing">
      <Container className="tab-content">
        <Row>
        {props.data.length > 0 && props.data.map((data, i) => {
        let eat_in = ''
        if(data.Eat_in == true) {
          eat_in = "eat_in"
        }
        let takeaways = ''
        if(data.Takeaways == true) {
          takeaways = "takeaways"
        }
          return (
            <Col xl={6} className={`mg-list ${eat_in} ${takeaways}`}>
                <Link to={`/about/loving-life/${props.url}/${data.URL}`} className="link">
                    <div className={`favourite-item d-flex ${count == i+1 ? 'no-border' : ''} ${type == "even" ? 'no-border' : ''}`}>
                        <figure>
                            {data.Tile_Image ? <img loading="lazy" src={data.Tile_Image.childImageSharp && data.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${data.Name} - Martyn Gerrard`} /> : '' }
                            {data.Tile_Image_Label ? <span className="tag-abs text-uppercase">{data.Tile_Image_Label}</span> : '' }
                        </figure>
                        <div className="info">
                            <h4 className="info-title">{data.Name}</h4>
                            <span className="add-txt">{data?.Address?.substring(0, 50)} {data?.Address?.length > 50 ? '...' : ''}</span>
                            <ReactMarkdown source={data.Short_Description} allowDangerousHtml className=""/>
                        </div>
                    </div>
                </Link>
            </Col>
            
          );
        })}
        </Row>
      </Container>
    </section>
  );
  }

export default MgFavouritesList
